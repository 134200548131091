import '@dotlottie/player-component';
import MicroModal from 'micromodal';

MicroModal.init();

const queryId = 'query';
const successId = 'success';
const errorId = 'error';
const loaderId = 'loader';

const formElem = document.getElementById("email-form");
formElem.addEventListener("submit", onFormSubmit);

function hideElement(id) {
  const elem = document.getElementById(id);
  elem.classList.add("hidden");
}

function displayElement(id) {
  const elem = document.getElementById(id);
  elem.classList.remove('hidden');
}

function onSuccess() {
  hideElement(queryId);
  hideElement(errorId);
  hideElement(loaderId)
  displayElement(successId)
}

function onError() {
  hideElement(queryId);
  hideElement(successId);
  hideElement(loaderId)
  displayElement(errorId)
}

function setIsLoading() {
  displayElement(loaderId)
}

function onFormSubmit(e) {
  e.preventDefault();
  const data = new FormData(formElem);
  setIsLoading()
  fetch(process.env.FORM_EP, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: data.get('email'),
      name: data.get('name'),
      field: data.get('field'),
    }),
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        onSuccess();
        return;
      }
      onError();
    })
    .catch(() => { onError(); });
}

